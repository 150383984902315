@import-normalize;
@import "./styles/mixins";
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Mono&family=Noto+Serif:wght@400;700&display=swap');

/*
  1. Globals
  1.1. Animations
  1.2. Common UI elements
  1.2.1. Global html table styles
  1.2.1.1. In-table images preview and modal viewer
  1.2.1.2. Table footer and pagination
  1.2.1.3. Table hat (spoilered content)
  1.2.2. Toggle UI element
  1.2.3. classyCheckbox element
  1.2.4. Chrome input filling fix
  1.2.5. Input of a type number
  1.2.6. react-select styling
  1.2.7. Modals (pop-ups)
  1.2.8. Spoilers
  1.2.9. Inline comment icon
  2. Form to add vehicles to journal
  2.1. Control buttons
  2.2. Form's state containers
  3. Settings page
  3.1 History
  3.2 All brands
  4. Navigation
  4.1. Navigation media query
  5. Journal table
  5.1 File uplading input styling
  5.1 Blacklist
  5.2 Active deposits in journal
  6. Local pedestrians table
  7. Accounting page
  7.1. Debts
  7.2. Short on deposits
  7.2.1. Add deposit modal dialog
  7.3. Construction areas reports
  8. All deposits page
  9. NotWelcomePage
  10. Blacklist page
  11. Main (~global) search
  12. Contacts page
  13. Local vehicles table
*/



/*
  1. Globals
*/

// first color palette:
$white: #E4E1DF;
$light-shades: $white;
$tr-light-shades: rgba(228, 225, 223, 0.7);
$light-accent-color: #1F4068;
$accent-color: #FE016C;
$tr-accent-color: rgba(254, 1, 108, 0.7);
$dark-accent-color: #1B1B2F;
$tr-dark-shades: rgba(22, 36, 71, 0.7);
$dark-shades: #162447;

// second color palette
// made at https://coolors.co/e4e1df-a4b7b5-fe016c-3c3c68-0e172f
$white: rgb(228, 225, 223); // #e4e1df
$light-shades: $white;
$tr-light-shades: rgba(164, 183, 181, 0.7);
$light-accent-color: rgb(164, 183, 181); // #A4B7B5
$accent-color: rgb(254, 1, 108); // #FE016C
$tr-accent-color: rgba(254, 1, 108, 0.7);
$dark-accent-color: rgb(60, 60, 104); // #3c3c68
$tr-dark-shades: rgba(60, 60, 104, 0.7);
$dark-shades: rgb(14, 23, 47); // #0e172f


:root {
  min-width: 100%;
  width: max-content;
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  background: var(--light-shades);

  --this-white: #{$white};
  --light-shades: #{$light-shades};
  --tr-light-shades: #{$tr-light-shades};
  --tr-dark-shades: #{$tr-dark-shades};

  --light-accent-color: #{$light-accent-color};
  --accent-color: #{$accent-color};
  --tr-accent-color: #{$tr-accent-color};
  --dark-accent-color: #{$dark-accent-color};
  --dark-shades: #{$dark-shades};

  --safe-area-horizontal: 4rem;
  --inputHeight: 3rem;
  --inputPadding: 0.3em;
  --valueContainerHeight: calc(var(--inputHeight) - var(--inputPadding) * 2);
  --gap-minimal: 1.618rem;
  --gap-S: calc(var(--gap-minimal) * 1.618);
  --gap-M: calc(var(--gap-S) * 1.618);
  --gap-L: calc(var(--gap-M) * 1.618);
  --gap-XL: calc(var(--gap-L) * 1.618);
  --dashboardHeight: calc(var(--gap-minimal) * 2 + var(--inputHeight) / 1.618 + 1.2rem);
  --ani-speed: 450ms;

  --links-size: var(--gap-M);

  --toggle-border-style: 1px solid var(--tr-accent-color);
  --toggle-offset:  5px;
  --toggle-diameter: var(--gap-minimal);
  --toggle-width: calc(var(--toggle-diameter) * 2.618 + var(--toggle-offset) * 2);


  padding: 0 var(--safe-area-horizontal) 0 var(--safe-area-horizontal);
  font-size: 16px;
  color: var(--dark-shades);
  @include text-style;
}

/* can be used for smoother dark/light themes transition:
:root.transition,
:root.transition *,
:root.transition *:not([class*="Toggle"]):before,
:root.transition *:after {
  transition: cubic-bezier(0.68, -0.55, 0.27, 01.55) calc(var(--ani-speed) * 4) !important;
  transition-delay: 0!important;
}
*/

@media (prefers-reduced-motion: reduce) {
  :root {
    --ani-speed: 0ms;
  }
}

:root[data-theme='dark'] {
  --light-shades: #{$dark-shades};
  --tr-light-shades: #{$tr-dark-shades};
  --tr-dark-shades: #{$tr-light-shades};

  --light-accent-color: #{$dark-accent-color};
  --dark-accent-color: #{$light-accent-color};
  --dark-shades: #{$light-shades};
}

body, #root {
  margin: 0;
  box-sizing: border-box;
  min-width: 100%;
  height: 100%;
}

#root {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

body.scroll-blocked {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

*::selection {
  color: var(--dark-shades);
  background-color: var(--accent-color);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}


h1, h2, h3, h4 {
  @include heading-style;
}

p {
  @include text-style;
}

a {
  color: var(--accent-color);
}

footer {
  box-sizing: border-box;
  width: 100%;
  max-width: calc(100vw - var(--safe-area-horizontal) * 2 - var(--safe-area-horizontal) / 4);
  transform: translateX(0.5rem);
  position: sticky;
  position: -webkit-sticky;
  left: var(--safe-area-horizontal);
  margin-top: auto;
  pointer-events: none;

  #sticky-footer {
    display: flex;
    box-sizing: border-box;
    width: 100%;
    flex-flow: row;
    justify-content: center;
    margin-top: var(--gap-XL);
    background-color: var(--light-shades);
    border-top: 1px solid var(--dark-shades);
    @include select-none;
    text-align: center;
  }
}

// @media only screen and (max-width: 600px) and (orientation: portrait) {
//   footer {
//     left: 2rem;
//     max-width: calc(100vw - 4.5rem);
//   }
// }

@media only screen and (max-width: 600px) and (orientation: portrait) {
  :root {
    // font-size: 24px;
    --safe-area-horizontal: 2rem;
  }
}


/*
  1.1. Animations
*/

@keyframes fadingOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}


@keyframes unfold {
  from {
    opacity:0;
  }

  to {
    opacity: 1;
    visibility: visible;
    // pointer-events: all;
  }
}

@keyframes rotate {
  from {
    transform: rotate(1deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes reverse-rotate {

  from {
    transform: rotate(-1deg);
  }

  to {
    transform: rotate(-360deg);
  }
}

@keyframes loading-line {
  100% {
    transform: translateX(50%);
  }
}


/*
  1.2. Common UI elements
*/


/**
* Global button styling
*/

button {
  display: inline-block;
  border: none;
  padding: 0;
  margin: 0;
  text-decoration: none;
  background: none;
  font-weight: 700;
  font-family: inherit;
  font-size: 1em;
  cursor: pointer;
  text-align: center;
}

.button {
  display: block;
  padding: 1em 1.5em;
  color: var(--this-white);
  // color:blue;
  background-color: var(--tr-accent-color);
  border-radius: 0.2em;
  border: 1px solid var(--accent-color);
  outline: 2px solid transparent;
  outline-offset: -0.3em;
  transition:
  background-color var(--ani-speed) ease-in-out,
  outline var(--ani-speed) ease-in-out,
  text-shadow var(--ani-speed) ease-out,
    box-shadow var(--ani-speed) ease-in;
    -webkit-appearance: none;
    -moz-appearance: none;
    @include select-none;

  &:hover:not(:disabled),
  &:focus {
    box-shadow: 0 0.05em 0.75em 0.05em var(--tr-accent-color);
    background: var(--accent-color);
    outline-color: var(--tr-light-shades);
  }

  &:active {
    box-shadow: 0 0.05em 0.35em 0.05em var(--tr-accent-color);
  }

  &:disabled {
    color: var(--light-accent-color);
    background-color: transparent;
    border: 1px solid var(--light-accent-color);
    cursor: not-allowed;

  }
}

:root[data-theme='light'] .button:disabled {
  svg {
    stroke: var(--dark-accent-color) !important;
  }
}


.button.dev {
  position: absolute;
  bottom: 7rem;
  left: 2rem;
}

.page-container {
  margin: 0 auto 0 auto;
  box-sizing: border-box;
  min-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

#login-form {
  width: 100%;

  .boxProperty {
    width: 36ch;
  }

  input {
    text-align: center;
  }
}




/**
  1.2.1. Global html table styles
*/

table {
  display: table;
  box-sizing: border-box;
  margin: 0 auto;
  table-layout: fixed;
  text-align: center;
  border-collapse: collapse;
  // overflow: scroll;
  @include select-any;

  thead {
    td, th {
      box-sizing: border-box;
      border: 1px solid var(--light-accent-color);
      margin:0;
      // overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-weight: 400;
    }
  }

  tbody {
    td {
      box-sizing: border-box;
      border: 1px solid var(--light-accent-color);
      /* overflow: hidden; */
      white-space: nowrap;
      text-overflow: ellipsis;
      padding: 1.618ch;
      max-width: 14ch;
    }
  }

  tr.deleted {
    display: none;
  }

}

[id$="-table-container"] {
  max-width: 100%;
  margin: 0 auto 0 auto;
  position: relative;
  text-align: center;
  @include select-none;

  #toggle-container {
    display: block;
    width: calc(100vw - 9rem);
    position: sticky;
    position: -webkit-sticky;
    left: var(--safe-area-horizontal);
  }

  .Toggle {
    position: relative;
    display: flex;
    width: 23ch;
    left: calc(50vw - var(--safe-area-horizontal));
    transform: translateX(-50%);
    flex-flow: column-reverse;
    margin: 0;
    margin-top: calc(var(--gap-L) - var(--gap-S));
    margin-bottom: calc(var(--gap-minimal) - 0.5ex);

    text-align: center;
    font-size: 1.618em;
    line-height: 1.2em;
    cursor: pointer;
    gap: 0;

    .Toggle__display {
      margin: 0;
      margin-bottom: calc(var(--gap-minimal) - 0.5ex);;
    }

    .Toggle__input:not(:checked) + .Toggle__display {

      &::before {
        border-color: var(--accent-color);
        background-color: var(--tr-accent-color);
      }
    }
  }

  .Toggle.only-hat {
    margin-top: 0;
  }

}

[id$="-table-entity"] {
  --col-settings-height: 2.618rem;
  position: relative;
  padding-left: 2rem;
  @include select-none;

  [class^="header-container-"] {
    position: relative;
    padding: 1.618ch;
    padding-top: calc(var(--col-settings-height) + 2ex);

    &:focus-within {
      border-radius: 4px;
      outline: 2px solid var(--tr-accent-color);
      outline-offset: -2px;
    }

    p {
      margin: 0;
      line-height: 1.2rem;
    }

  }

  thead {
    background-color: var(--light-shades);
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 10;

    th {
      position: relative;
      padding-top: 0;
      background-color: var(--light-shades);
      border-style: none;
      overflow: visible;
      vertical-align: bottom;
      @include select-none;

      .column-header {
        height: 1.618rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }

    }

    .column-settings-container {
      display: inline-flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 50%;
      top: 1ex;
      transform: translateX(-50%);
      box-sizing: border-box;
      height: var(--col-settings-height);
      min-width: var(--col-settings-height);
      border: transparent 1px solid;
      border-radius: var(--col-settings-height);
      z-index: 10;
      gap: 1ch;
      background-color: transparent;
      transition:
        all ease-in var(--ani-speed),
        background-color ease-in calc(var(--ani-speed) * 1.618),
        border-color ease-in var(--ani-speed);

      .column-setting, .reveal-controls {
        display: none;
        padding: 0;
        background-color: transparent;
        border: none;
        height: min-content;
        line-height: 1rem;

        .icon-fold, .icon-settings {
          width: 1.618em;
          height: 1.618em;
          color: var(--tr-light-shades);
          transition: color linear var(--ani-speed);
          transform: rotate(20deg);
          animation: none;
        }

        .icon-fold {
          color: var(--dark-shades);
          transform: rotate(90deg);
        }

        &:hover:not(:disabled) {

          .icon-fold {
            color: var(--accent-color);
          }

        }

      }

      .column-setting:disabled {
        svg {
          opacity: 0.6;
          cursor: not-allowed;
          color: var(--dark-accent-color);
        }
      }

      &:hover, &:focus-within {
        background-color: var(--light-accent-color);
        border: var(--light-accent-color) 1px solid;


        .reveal-controls {
          background-color: transparent;

          .icon-settings {
            color: var(--this-white);
          }
        }

      }

    }

    .column-settings-container[expanded$="false"] {
      .column-setting {
        display: none;
      }

      .reveal-controls {
        display: initial;
      }

    }

    .column-settings-container[expanded$="true"] {
      background-color: var(--light-accent-color);
      padding-left: 0.8rem;
      padding-right: 0.8rem;

      .column-setting {
        display: initial;
      }
      .reveal-controls {
        display: none;
      }

    }

    .column-filter-container {
      position: relative;
      height: 1.618rem;
      color: var(--tr-accent-color);

      .icon-list-search {
        position: absolute;
        right: 0;
        top: 0.2ex;
        width: auto;
        height: 1.618em;
        cursor: pointer;
        transition: all linear var(--ani-speed);


        &:hover {
          color: var(--accent-color);
          filter: drop-shadow(
            0 0.05em 0.16em var(--tr-accent-color)
          );
        }
      }

    }

    .column-filter {
      width: 20ch;
      height: 100%;
      color: var(--tr-accent-color);
      background-color: transparent;
      border: none;
      text-align: center;
      font-size: 1rem;
      padding: 0 1.618em;
      box-sizing: border-box;
      cursor: pointer;

      &::placeholder {
        color: var(--dark-shades);
        opacity: 1;
      }

      &:focus {
        outline: none;

        &::placeholder {
          color: var(--light-accent-color);
        }

      }

    }

  }

  td.boxProperty {
    display: table-cell;
    position: relative;
    padding-left: var(--gap-minimal);
    padding-right: var(--gap-minimal);
    width: unset;

  }

  td.boxProperty.cell-in-object,
  td.boxProperty.cell-in-dest {
    max-width: calc(14ch * 1.618);
    white-space: nowrap;
    text-overflow: initial;
  }

  td.boxProperty.cell-in-number {

  }

  td.boxProperty.cell-in-object span,
  td.boxProperty.cell-in-dest span,
  td.boxProperty.cell-in-status span,
  td.boxProperty.cell-in-created_by span,
  td.boxProperty.cell-in-entry_point span {

    @include land-object;

  }

  td.boxProperty.cell-in-docs,
  td.boxProperty.cell-in-useful_photo {
    max-width: calc(14ch * 1.618);
    min-width: 14ch;
    white-space: nowrap;
    text-overflow: initial;
  }

  span.on-construction {

    svg.icon-backhoe {
      --height: 1.4rem;
      width: var(--height);
      height: var(--height);
      position: absolute;
      left: calc(var(--height) * -1.23);
      top: 14%;
      // color: var(--light-accent-color);
      color: var(--dark-shades);
    }

  }


  td.boxProperty.cell-in-brand,
  td.boxProperty.cell-in-category {
    max-width: unset;
    overflow: visible;

    [class*="-container"] {
      max-width: 18ch;
    }

    [class*="-singleValue"] {
      text-align: left;
      height: 1.3em;
    }

  }

  td.boxProperty.cell-in-comment,
  td.boxProperty.cell-in-email,
  td.boxProperty.cell-in-phone {
    max-width: unset;

    .classyInput {
      background-color: transparent;
      color: var(--dark-shades);
      padding: 0.8em calc(var(--inputPadding) * 1.618);
      width: calc(18ch * 1.618);
      line-height: 1.2em;
      white-space: normal;
      // resize: none;
      resize: vertical;
    }
  }

  td.boxProperty.cell-in-entry_point,
  td.boxProperty.cell-in-created_by {

    span {
      display: block;
      @include select-value;
      background-color: transparent;
      border-color: var(--light-accent-color);
      width: min-content;
      line-height: 1.618em;
      margin-left: auto;
      margin-right: auto;
    }

  }



  td.boxProperty.cell-in-updated_at,
  td.boxProperty.cell-in-entered_at,
  td.boxProperty.cell-in-created_at {
    width: 20ch;
    white-space: normal !important;
  }

  .delete-row.minimal {
    color: var(--tr-accent-color);
    transition: all linear var(--ani-speed);

    svg:last-of-type {
      display: none;
    }

    svg {
      width: 1.618rem;
      height: auto;
    }

    &:hover:not(:disabled) {
      color: var(--accent-color);
      filter: drop-shadow(
        0 0em 0.06em var(--tr-accent-color)
      );
    }
  }

  td.boxProperty.cell-in-origin_ID {
    @include select-any;
  }



  .delete-row.minimal:disabled {
    // opacity: 0.7;
    color: var(--light-accent-color);
    cursor:auto;
  }

  .delete-row.minimal.fallback {

    svg:first-of-type {
      display: none;
    }

    svg:last-of-type {
      display: initial;
    }

    svg {
      animation: reverse-rotate linear calc(var(--ani-speed) * 9) infinite,
      fadingOut linear 5s;
      stroke: var(--accent-color) !important;
    }

  }

  [class*="select__menu"] {
    text-align: left;
    border-color: var(--accent-color);
  }

  .not-cool-status-container {
    position: relative;
  }

  .not-cool-status {
    position: sticky;
    left: calc(var(--safe-area-horizontal) * 1.618 + 1.618ch);
    top: 0;
    width: max-content;

    // TODO: find a way to make this look better
    // transform: translateX(calc(50vw - 1.618ch - 50% - var(--safe-area-horizontal)));
    // transform: translateX(calc(50vw - 50% - (var(--safe-area-horizontal) * 2)));
  }

}

.error-boundary {

  .error-message {
    display: block;
    max-width: 44ch;
    margin: var(--gap-M) auto var(--gap-S) auto;
    @include select-any;
  }

  .button {
    margin-bottom: var(--gap-M);
    margin-left: auto;
    margin-right: auto;
  }

}

#column-settings, #column-refresh {
  position: initial;
  border: none;
  width: 0;
  height: 100%;
  overflow: visible;
  padding: 0;
  padding-left: 12ch;

  .expand-columns, .refresh-columns {
    width: max-content;
    position: absolute;
    bottom: 50%;
    right: 0;
    right: calc(1.618em * 1.618);
    transform: translateY(50%);

    .icon-show-all, .icon-refresh {
      width: calc(1.618em * 1.618);
      height: calc(1.618em * 1.618);
      color: var(--tr-accent-color);
      transform: rotate(90deg);
      transition: all linear var(--ani-speed);
    }

    &:hover, &:focus-visible {
      .icon-show-all, .icon-refresh {
        color: var(--accent-color);
        filter: drop-shadow(
          0 0em 0.06em var(--tr-accent-color)
        );
      }
      outline: none;
    }

  }

  .expand-columns.reverse {

    .icon-show-all {
      transform: rotate(270deg);
    }

  }

}

#column-refresh {
  padding-left: 0;

  .refresh-columns {
    right: 0;
    left: calc(var(--safe-area-horizontal) * -0.5);
    bottom: 0;
    transform: translateY(calc((1.618ch - 0.5ex) * -1)) translateX(-50%);

    .icon-refresh {
      width: 1.618em;
      height: 1.618em;
    }

  }
}

@media only screen and (max-width: 600px) and (orientation: portrait) {
  [id$="-table-entity"] {

    thead {
      .hide-column {
        margin-bottom: 1.618em;
      }
    }
    td.boxProperty.cell-in-number {
      max-width: 18ch;
    }

    td.boxProperty.cell-in-brand,
    td.boxProperty.cell-in-category {
      max-width: 18ch;

      [class*="-container"] {
        width: unset;

      }

    }
  }
}


/*
    1.2.1.1. In-table images preview and modal viewer
*/

.react-viewer-mask {
  background-color: rgba(255, 255, 255, 0.818) !important;
}

:root[data-theme='dark'] {

  .react-viewer-mask {
    background-color: rgba(0, 0, 0, 0.818) !important;
  }

}

.react-viewer-transition {
  transition: opacity 1ms ease-out !important;
}

.react-viewer-footer {
  pointer-events: none;
}

ul.react-viewer-toolbar {
  margin-bottom: var(--gap-M) !important;
  margin-top: var(--gap-S) !important;
  height: var(--gap-M) !important;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  gap: var(--gap-minimal);
  // background-color: var(--light-shades);
}

li.react-viewer-btn {
  color: var(--accent-color) !important;
  width: var(--gap-S) !important;
  height: var(--gap-S) !important;
  text-align: center;
  background: none !important;
  border-radius: 0;
  pointer-events: all !important;

  svg {
    width: auto;
    height: 100%;
    opacity: .412;
    transition: all linear var(--ani-speed);
  }
  svg.icon-chevron {
    height: var(--gap-M) !important;
    transform: translateY(-20%) translateX(-20%);
  }

  &:hover,
  &:focus {
    outline: none !important;

    svg {
      opacity: 1;
    }

  }
}


.image-in-table-container {
  --images-gap: calc(var(--inputHeight) / 1.618);
  display: flex;
  padding: 0;
  margin: 0;
  flex-flow: row wrap;
  gap: var(--images-gap);
}

.image-in-table-preview {
  max-height: var(--inputHeight);
  width: auto;
  cursor: pointer;
}


/*
    1.2.1.2. Table footer and pagination
*/

[id$="-table-fake-footer-container"] {
  width: calc(100vw - var(--safe-area-horizontal) * 2 - 1rem);
  position: sticky;
  position: -webkit-sticky;
  left: calc(var(--safe-area-horizontal) + 0.2rem);
}

[id$="-table-fake-footer"] {
  width: 100%;
  font-size: 0.618rem;
  color: var(--dark-shades);
  @include select-none;

  p {
    width: 100%;
    text-align: center;
    transition: opacity var(--ani-speed) ease-in-out;
  }

  div {
    transition: opacity var(--ani-speed) ease-in-out;
  }


}

[id$="-pagination-controls"] {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  box-sizing: border-box;
  font-size: 1rem;
  margin-top: var(--gap-minimal);
  margin-bottom: var(--gap-XL);
  align-items: center;
  gap: var(--gap-minimal);

  & > * {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
  }

  .pagination-buttons-container {
    font-size: 1rem;
    display: flex;
    flex-grow: 1;
    flex-flow: row;
    align-items: center;
    width: 100%;

    .button.pagination {
      height: var(--inputHeight);
      margin: 0 calc(var(--inputHeight) / 1.618 / 2);
      padding-top: 0;
      padding-bottom: 0;
    }

    .button.pagination.fast {
      transform: scale(0.618);
    }
  }

  .go-to-page {
    color: var(--light-accent-color);

    p {
      padding: 0;
      margin-left: 1.618ch;
      position: relative;
      bottom: 0.1ex;
      width: initial !important;

    }

    input {
      width: 10ch;
      margin-right: auto;
      margin-left: 1.618ch;
      color: var(--dark-shades);
      @include input-style;
      height: calc(var(--inputHeight) / 1.618);

      &:hover, &:focus {
        border-color: var(--accent-color) !important;
        box-shadow: var(--accent-color) 0 0 0 1px;
        border-width: 1px !important;
      }

    }


  }

  .boxProperty.records-per-page {
    width: 23ch;
    margin: 0;
    margin-left: auto;
    color: var(--dark-shades);
    margin-right: 1.618ch;

    [class*="-control"] {
      border-color: var(--light-accent-color) !important;

      &:hover, &:focus-within {
        border-color: var(--accent-color) !important;
        cursor: pointer;
      }

    }

    [class*="-indicatorSeparator"] {
      background-color: var(--light-accent-color) !important;
    }

    .select__value-container, .select__indicators {
      position: relative;
      top: -50%
    }

    [class*="-ValueContainer"] {
      min-height: unset;
      width: 18ch;
    }

    [class*="select__menu"] {
      border-color: var(--light-accent-color);
    }

    [id*="-records-per-page"] {
      margin-left: auto;

      .select__control {
        min-height: unset;
        height: calc(var(--inputHeight) / 1.618);
      }

    }

  }


  .go-to-page input {
    position: relative;
    font-size: 0.8rem;
    padding-left: 1.618ch;
    border-color: var(--light-accent-color);


    &:focus, &:focus {
      border-color: var(--accent-color);
      border-width: 2px;
      outline: none;
    }
  }
}

@media only screen and (max-width: 600px) and (orientation: portrait) {

  [id$="-pagination-controls"] {
    .pagination-buttons-container {
      justify-content: space-between;

      .button.pagination {
        height: calc(var(--inputHeight) / 1.618);
        margin: 0;
        padding-left: 1.618ch;
        padding-right: 1.618ch;

      }

    }

    .go-to-page, .records-per-page {
      display: none;
    }

  }
}

/*
  1.2.1.3. Table hat (spoilered content)
*/

[id*="-table-container"] {

  .spoiler {
    max-width: calc(100vw - var(--safe-area-horizontal) * 2 - var(--safe-area-horizontal) / 4);
    position: sticky;
    position: -webkit-sticky;
    left: var(--safe-area-horizontal);

  }

}

@media only screen and (max-width: 600px) and (orientation: portrait) {

  [id*="-table-container"] {
    .Toggle {
      font-size: 1.13em;
    }
  }

}


/**
  1.2.2. Toggle UI element
*/

.Toggle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  gap: calc(var(--gap-minimal) / 1.618) 0;
}

.Toggle__display {
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  width: var(--toggle-width);
  height: calc(var(--toggle-diameter) + var(--toggle-offset) * 2);
  box-sizing: content-box;
  border: var(--toggle-border-style);
  color: var(--dark-shades);
  position: relative;
  border-radius: 100vw;
  margin-left: var(--gap-minimal);
  background-color: var(--light-shades);
  transition: border ease-in var(--ani-speed),
    color ease-in var(--ani-speed),
    background-color ease-in var(--ani-speed);
  outline-color: var(--accent-color);
  cursor: pointer;
}

.Toggle__display::before {
  content: '';
  width: var(--toggle-diameter);
  height: var(--toggle-diameter);
  border-radius: 50%;
  box-sizing: border-box;
  border: var(--toggle-border-style);
  position: absolute;
  z-index: 2;
  top: 50%;
  left: var(--toggle-offset);
  transform: translate(0, -50%);
  background-color: var(--this-white);
  transition: border ease-in var(--ani-speed),
    color ease-in var(--ani-speed),
    transform ease-in var(--ani-speed),
    background-color ease-in var(--ani-speed);
}

:root[data-theme='dark'] .Toggle__display::before {
  background-color: var(--light-shades);
}


.Toggle__input {
  opacity: 0;
  width: 0;
  height: 0;
  transition: box-shadow linear var(--ani-speed);
}

.Toggle__input:focus:focus-visible + .Toggle__display,
.Toggle__input:hover:not(:disabled) + .Toggle__display {
  box-shadow: 0 0.05em 0.75em 0.05em var(--tr-accent-color);
  &::before {
    box-shadow: 0 0.02em 0.35em 0.01em var(--tr-accent-color);
    outline-color: var(--accent-color);
  }
}

.Toggle__input:checked + .Toggle__display {
  background-color: var(--tr-accent-color);
}

.Toggle__input:checked + .Toggle__display::before {
  transform: translate(calc(var(--toggle-width) - 100% - var(--toggle-offset) * 2), -50%);
  background-color: var(--this-white);
}

.Toggle[disabled],
.Toggle[data-disabled="true"] {
  opacity: 0.6;
  cursor: not-allowed;
}

.Toggle__input:disabled + .Toggle__display {
  opacity: 0.6;
  cursor: not-allowed;
  --toggle-border-style: 1px solid var(--dark-accent-color);
  color: var(--dark-accent-color);

  &::before {
    background-color: var(--light-shades);
  }

}


/**
  1.2.3. classyCheckbox element
*/

.checkboxContainer {
  width: 15ch;
  display: block;
  display: flex;
  align-items: center;
  position: relative;
  transform: translateX(calc(50% - calc(var(--inputHeight) / 2)));
}

.classyCheckbox {
  appearance: none;
  margin: 0 auto;
  // background-color: var(--light-shades);
  background-color: transparent;
  height: var(--inputHeight);
  width: var(--inputHeight);
  border-color: var(--dark-accent-color);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  display: grid;
  place-content: center;
  cursor: pointer;

  &::before {
    content: "";
    width:  var(--inputHeight);
    height: var(--inputHeight);
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    background-color: var(--tr-accent-color);
    border-radius: 4px;
    border-color: var(--dark-accent-color);
    border-width: 1px;
  }
}

.classyCheckbox:indeterminate::before {
  // border-color: lime;
  transform: scale(0.65, 0.13)
}

.classyCheckbox:checked::before {
  transform: scale(0.65);
}

.classyCheckbox:hover:not(:disabled), .classyCheckbox:focus {
  border-color: var(--accent-color) !important;
  border-width: 2px;
  outline: none !important;
}

.classyCheckbox:disabled {
  border-color: var(--light-accent-color) !important;
  cursor: default;

  &::before {
    background-color: var(--light-accent-color) !important;
  }
}


/**
      1.2.4. Chrome input filling fix
* Below is a sick fix for chrome behaivour setting imput's background
* to a white color with !important flag when suggested value used
* found finx here: https://stackoverflow.com/questions/61083813/how-to-avoid-internal-autofill-selected-style-to-be-applied
*/

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}


/**
  1.2.5. Input of a type number
* below styles hide controls inside
* input of a type number
* thx to https://stackoverflow.com/a/40189766/15054547
*/

input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
  margin: 0;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
}


/**
  1.2.6. react-select styling
*/

:root[data-theme='dark'] .boxProperty {
  label {
    color: var(--this-white);
  }

  [class*="-control"], .classyInput {
    border-color: var(--this-white);
  }

  [class*="-indicator"] {
    color: var(--this-white);
  }

  [class*="-indicatorSeparator"] {
    background-color: var(--this-white);
  }

  .checkboxContainer .classyCheckbox {
    border-color: var(--this-white);
  }

}

:root .boxProperty {
  position: relative;
  max-width: 64ch;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: var(--gap-S) auto 0 auto;
  opacity: 1;
  transition: opacity linear var(--ani-speed);


  .propertyContainer {
    position: relative;
    flex-basis: 64ch;
  }



  /*#region overriding react-select inline styles */

  .classyInput {
    border-color: var(--dark-accent-color);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    display: block;
  }

  .classyInput:disabled {
    border-color: var(--light-accent-color);
  }

  [class*="-control"], .classyInput {
    min-height: var(--inputHeight);
    height: var(--inputHeight);
  }

  #destination [class*="-control"]{
    height: unset;
  }

  [id*="-listbox"] {
    @include text-style;
  }

  [class*="select__menu"] {
    z-index: 13000 !important;
    border: 1px solid var(--dark-accent-color);
    border-radius: 0.3em;

    [class*="-option"]:hover {
      border-bottom: 0 solid;
      border-style: none;
    }

    [class*="option--is-focused"] {
      background-color: transparent;
      background-color: var(--tr-accent-color);

    }

    [class*="option--is-selected"] {
      background-color: #FE016C;
    }
  }

  [class*="-control"], [class*="--is-focused"], [class*="-input"], .classyInput {

    box-sizing: border-box;
    width: 100%;
    cursor: default;
    font-size: 1rem;
    color: var(--dark-shades);
    box-shadow: none;
    @include text-style;

    input {
      @include text-style;
    }

    &:hover:not(:disabled), &:focus-visible:not(:disabled), &:focus:not(:focus-visible):not(:disabled), &:focus-within:not(:disabled) {
      outline: none;
      border-color: var(--accent-color);
      box-shadow: var(--accent-color) 0 0 0 1px;
    }
  }



  [class*="-ValueContainer"] {
    min-height: var(--valueContainerHeight);
    padding: 0;
    width: 15ch;

  }

  // [class*="-Input"] {
  [class*="__input-container"] {
    height: unset;
    min-height: unset;
    padding: 0;
    margin: 0;
    color: var(--dark-shades);
  }

  [class*="-singleValue"] {
    color: var(--dark-shades);
  }



  [class*="-placeholder"] {
    color: var(--dark-accent-color);
  }

  [class*="-indicator"] {
    color: var(--dark-accent-color);
  }

  [class*="-indicator"]:hover {
    color: var(--accent-color);
  }

  [class*="-indicatorSeparator"] {
    background-color: var(--dark-accent-color);
  }

  [class*="-input"] {
    height: unset;
    min-height: unset;
    padding: 0;
    margin: 0;
  }



  [class*="-menu"] {
    background-color: var(--light-shades);
  }

  [class*="multiValue select__multi-value"] {
    background-color: transparent;
    margin-right: calc(var(--inputPadding) * 1.618);
    margin-right: calc(var(--inputPadding) * 1.618);
  }

  [class*="value__label"] {
    @include select-value;
  }

  [class*="multi-value__remove"] {
    background-color: var(--tr-accent-color);
    border: 1px solid var(--accent-color);
    border-radius: 4px;
    svg {
    color: var(--this-white);
    }

    &:hover {
      svg {
        color:var(--dark-accent-color);
      }
    }
  }

  /*#endregion overriding react-select inline styles */

  [class*="-control"],
  input {
    // background-color: var(--light-shades);
    background-color: transparent;

    border-color: var(--dark-accent-color);
    padding: var(--inputPadding) calc(var(--inputPadding) * 1.618);

    &:focus-visible {
      outline: 0.2em solid var(--light-accent-color);
      outline-offset: 0.3em;
      border-radius: 0.2em;
    }

    &:focus:not(:focus-visible) {
      outline: none;
    }
  }

  label {
    box-sizing: border-box;
    width: 100%;
    color: var(--dark-accent-color);
    position: absolute;
    top: -1.5em;
    font-size: 1em;
    text-align: center;
    margin-bottom: 0.3em;
  }

  .errorMessage {
    position: absolute;
    bottom: .9ex;
    left: 50%;
    text-align: center;
    transform: translateX(-50%) translateY(calc(100% + 0.5em));
    width: 100%;
    font-size: 0.8em;
    line-height: 1.2em;
    overflow: visible;
    white-space: nowrap;
    color: var(--accent-color)
  }

}

[class*="control--is-disabled"] {
  border-color: var(--light-accent-color) !important;

  [class*="-indicator"] {
    display: none;
  }

  [class*="-indicatorSeparator"] {
    display: none;
  }
}

:root .boxProperty.deleting {
  opacity: 0.3;
}


/*
  1.2.7. Modals (pop-ups)
*/

.ReactModal__Body--open {
  overflow: hidden;
} //used to prevent scrolling on the body when the modal is open

.ReactModal__Body--open,
.ReactModal__Html--open {
  overflow: hidden;
} //to stop the page to scroll to the top when open a modal

.ReactModal__Overlay {
  background-color: transparent;
  transition: background-color var(--ani-speed) ease-in;
}

.ReactModal__Overlay--after-open{
  background-color: var(--tr-light-shades);
}

:root[data-theme='dark'] .ReactModal__Overlay--after-open {
  background-color: rgba(0,0,0,0.7);

}

.ReactModal__Overlay--before-close{
  background-color: transparent;
}

.Overlay {
  z-index: 19;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.Modal {
  --cb-padding: var(--gap-M);
  --close-button-size: var(--gap-S);
  --cb-safe-area: calc(var(--cb-padding) + var(--close-button-size));
  box-sizing: border-box;
  position: absolute;
  z-index: 20;
  min-width: min(calc(100% - var(--safe-area-horizontal) * 2), 24ch);
  min-height: min(calc(100% - var(--safe-area-horizontal) * 2), 24ch);
  max-height: calc(100vh - var(--safe-area-horizontal) * 2);
  overflow: scroll;
  border: 1px solid var(--light-accent-color);
  border-radius: 4px;
  padding: var(--cb-safe-area) var(--cb-padding) var(--cb-padding) var(--cb-padding);
  background: var(--light-shades);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border-radius: 0.5em;
  outline: none;
  text-align: center;
  @include select-none;

  h1 {
    margin-top: -0.5ex;
    margin-bottom: var(--gap-S);
  }

  .confiration-message {
    margin: 0 0 var(--gap-S);
    font-size: calc(2rem / 1.618);
  }

  .deposit-comment {
    font-size: calc(2rem / 1.618);
    padding: 1.618ex;
    border: 1px solid var(--tr-accent-color);
    border-radius: 4px;
    margin: 0;
  }

  .boxProperty {
    margin-top: var(--gap-M);
    margin-bottom: var(--gap-M);
    flex-wrap: wrap;
    gap: var(--gap-S);

    &:last-of-type {
      margin-top: var(--gap-L);
    }
  }

  .classyInput {
    background-color: transparent;
    color: var(--dark-shades);
    padding: 0.8em calc(var(--inputPadding) * 1.618);
    width: calc(14ch * 1.618 * 1.618);
    line-height: 1.2em;
    white-space: normal;
    resize: vertical;
    width: 100%;
  }

  .button {
    display: inline-block;
    margin: 0 var(--gap-S) 0 var(--gap-S);
    margin: 0;
  }

  .loading-icon {
    padding-bottom: 0;
  }

}

.closeModalButton {
  position: absolute;
  z-index: 3;
  top: 0;
  right: 0;
  margin: calc(var(--close-button-size) * 0.618);
  width: var(--close-button-size);
  height: var(--close-button-size);

  .icon-x {
    width: var(--close-button-size);
    height: var(--close-button-size);
    stroke: var(--tr-accent-color);
  }

  &:hover,
  &:focus {
    outline: none;

    svg {
      transition: all var(--ani-speed) ease-in;
      stroke: var(--accent-color);
    }
  }
}

@media only screen and (max-width: 700px) {

  .Modal {
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    min-height: 100%;
    border-radius: 0;
    border: none;
    overflow: auto;
    padding-top: var(--cb-padding);

    h1 {
      font-size: 1.618rem;
      margin-top: 1ex;
    }

    .confiration-message {
      font-size: 1.13rem;
    }

    .boxProperty {
      label {
        font-size: 0.8rem;
      }

      label.file-upload-label {
        font-size: 1rem;
      }

      textarea.classyInput {
        height: calc(1.6rem + 3.6em) !important;
      }

    }

  }

}



/*
  1.2.8. Spoilers
*/

.spoiler {
  position: relative;
  box-sizing: border-box;
  width: 100%;
}

.spoiler.empty {
  animation: fadingOut calc(var(--ani-speed) * 1.618) forwards var(--ani-speed);
  pointer-events: none;
}

.spoiler-toggle {
  text-align: center;
}

.spoiler-header-button {
  position: relative;
  width: max-content;
  margin-top: -0.5ex;
  margin-bottom: var(--gap-S);
  margin-left: auto;
  margin-right: auto;

  @include text-style;
  color: var(--dark-shades);

  text-align: center;
  font-size: 1.618em;
  cursor: pointer;
  transition: color linear var(--ani-speed);
  outline: none !important;


  svg.chevrons {
    position: absolute;
    top: 0.25ex;
    left: -1.618em;

    color: var(--tr-accent-color);
    transition: color linear var(--ani-speed);
    height: 1em;

  }

  &:hover:not(:disabled), &:focus-visible:not(:disabled), &:focus:not(:disabled), &:focus-within:not(:disabled) {
    color: var(--accent-color);

    svg.chevrons {
      color: var(--accent-color);
    }

  }

  &:disabled {
    cursor: initial;

    svg {
      color: var(--tr-accent-color);
      opacity: 0.3;
    }
  }

  .items-count {
    position: absolute;
    left: 100%;
    bottom: 0.5ex;
    transform: scale(0.618);
    color: var(--light-accent-color);
  }

}

.spoiler-veil {
  display: none;
  opacity: 0;
  visibility: hidden;

  /*
    element's overflow is controlled in Spoiler.js
    to sync with animation
  */

  box-sizing: border-box;
  transition: height linear var(--ani-speed);
}

.spoiler-veil.loaded {
  display: block;
  animation: unfold calc(var(--ani-speed) * 1.618) forwards var(--ani-speed);
}

.spoiler-veil.collapsed {
  height: 0;
}

.spoiler-content-container {
  padding-bottom: var(--gap-M);
}

.spoiler [id$="-pagination-controls"] {
  margin-bottom: 0;
}

.loading-icon.spoiler-postfix {
  --loading-icon-size: 1.618em;
  position: absolute;
  top: -0.25ex;
  left: calc(var(--loading-icon-size) * -1);
  transform: none;
  padding-bottom: 0;
}


/*
  1.2.9. Inline comment icon
*/

.message-importante-container {
  position: relative;
  display: inline-block;
  height: 1em;
  width: 2.618ch;

  .message-importante {
    width: auto;
    height: 1.618em;
    position: absolute;
    left: 0;
    top: -0.309em;
    margin-left: 0.618ch;
    color: var(--accent-color);
    cursor: help;
  }
}

/*
  1.3.10. Blacklisted car item
*/

.button.bl-detail {
  display: block;
  position: relative;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: none;
  background-color: transparent;
  color: var(--tr-accent-color);
  margin-left: auto;
  margin-right: auto;

  svg {
    position: relative;
    top: 3%;
    width: var(--gap-minimal);
  }

  &:hover:not(:disabled),
  &:focus {
    box-shadow: none;
    background-color: transparent;
    outline: none;

    svg {
      color: var(--accent-color);
    }

  }
}

#release-from-blacklist-container {
  display: flex;
  width: calc(var(--gap-minimal) / 1.618);
  height: calc(var(--inputHeight) * 1.618);
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  justify-content: center;
  align-items: center;
  border-top-right-radius: 100%;
  border-bottom-right-radius: 100%;
  background-color: var(--tr-light-shades);
  @include select-any;

  #release-from-blacklist-button {
    display: block;
    position: relative;
    width: calc(var(--gap-minimal) * 1.618);
    height: calc(var(--gap-minimal) * 1.618);
    background-color: var(--light-accent-color);
    border-radius: 100%;
    border: none;
    padding: 0;
    cursor: pointer;
    outline: none;

    svg {
      position: relative;
      top: 6%;
      color: var(--tr-accent-color);
      height: var(--gap-minimal);
    }

    &:hover:not(:disabled),
    &:focus {
      box-shadow: none;
      background-color: var(--dark-shades);
      outline: none;

      svg {
        color: var(--accent-color);
      }

    }

  }

}

#blacklist-confirmation-container {
  min-width: min(46ch, 100%);
  min-height: min(46ch, 100%);

  h1 {
    line-height: 1.2em;
    margin-top: -0.6ex;
    margin-bottom: var(--gap-M);
  }

  .boxProperty {
    margin-top: var(--gap-S);
    margin-bottom: var(--gap-S);
    & > [class$="-container"] {
      width: 100%;
    }

    &:last-of-type {
      margin-top: var(--gap-S);
      margin-bottom: var(--gap-M);
      min-height: 3.2em;

    }

  }

  #error-on-submit-container {
    margin-top: 0;
    padding-top: 0;
  }

}

#addToBlacklistForm {
  display: none;

  .file-upload-label {
    top: 0;
  }

  .button.submit-to-blacklist {
    margin-bottom: calc(var(--gap-XL) - var(--gap-M))  !important;
  }

}

#addToBlacklistForm.visible {
  display: initial;
}

.bad-guest {
  --bad-guest-safe-area: 2.618ex;
  position: relative;
  display: flex;
  flex-flow: column;
  padding: var(--bad-guest-safe-area);
  border: 1px solid var(--light-accent-color);
  border-radius: 4px;
  max-width: 34ch;
  height: min-content;

  & > * {
    text-align: center;
    margin: 0;
  }

  .bl-details-container {
    display: flex;
    position: relative;
    width: 100%;
    min-height: var(--inputHeight);
    flex-flow: row wrap;
    border-radius: 4px;
    border: 1px solid var(--tr-light-shades);
    margin-top: calc(var(--gap-minimal) / 1.618);

    & > *:nth-child(2) {
      padding-top: 0;
    }

  }

}

.bad-guest-number {
  position: relative;
  font-size: 1.13em;
  letter-spacing: 1px;
  margin-top: -0.5ex;
  margin-left: 1.618em;
  margin-right: 1.618em;

  i {
    position: absolute;
    top: -0.4ex;
    left: calc(1.618em * -1.13);
    color: var(--tr-accent-color);

    .shield-x {
      color: var(--light-accent-color);
      height: 1.618em;
      width: auto;
    }

  }

  i.bad-guest-comment {
    left: unset;
    right: calc(1.618em * -1.13);
    cursor: help;
  }

}

.bad-guest-related-object {
  margin-top: -0.5ex;
  padding-top: calc(var(--gap-minimal) / 1.618);
}

.bad-guest-sanction {
  color: var(--tr-accent-color);
  padding-top: calc(var(--gap-minimal) / 1.618);
  margin-top: -0.5ex;
  margin-bottom: -0.5ex;
}

.bad-guest-comment.expanded {
  min-width: 100%;
  margin: 0;
  margin-top: -0.5ex;
  margin-bottom: -0.25ex;
  padding: var(--bad-guest-safe-area);
}

.bad-guest-photo-container {
  padding: var(--bad-guest-safe-area);
  width: 100%;
  height: 100%;

  .image-in-table-container {
    justify-content: center;
    align-items: center;
  }

  .image-in-table-preview {
    // max-width: calc(100% - var(--images-gap));
  }

}

@media only screen and (min-width: 600px) {
  h1 {
    br {
      display: none;
    }
  }
}


/*
  2. Form to add vehicles to journal
*/

#add-to-journal-wrapper {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  max-width: calc(100vw - var(--safe-area-horizontal) * 2 - var(--safe-area-horizontal) / 4);
}

#add-to-journal-form {
  position: relative;
  display: none;
  flex-basis: 44ch;
  margin-left: auto;
  margin-right: auto;
  padding-top: calc(var(--gap-L) - var(--gap-M));
}

#add-to-journal-form.visible {
  display: block;
}


ul#vehiclesList {
  margin: var(--gap-L) 0 0 0;
  padding: 0;
  min-width: 35ch;
  list-style: none;

}

.vehicleLine {
  position: relative;
  box-sizing: border-box;
  margin: var(--gap-M) 0;
  padding-top: calc(var(--gap-M) - var(--gap-S));
  border-top: solid 1px var(--dark-accent-color);
  border-bottom: solid 1px var(--dark-accent-color);

  .errorMessage {
    color: var(--dark-shades);
  }
}

.vehicleLine:first-of-type {
  margin-top: var(--gap-L);
}

.vehicleLine.deleting {
  & .boxProperty {
    opacity: 0.3;
    pointer-events: none;
  }
}

.vehicleLine.fadingOut {
  animation-duration: var(--ani-speed);
  animation-name: fadingOut;
}

.boxProperty.destinationBox {
  max-width: 64ch;
  flex-direction: column;
  margin-top: 0;
  margin-bottom: calc(calc(var(--inputHeight) + var(--gap-minimal)) * -1 );
  z-index: 2;

  .propertyContainer {
    flex-basis: unset !important;

  }

  #keyWordContainer {
    display: block;
    position: relative;
    color: var(--dark-shades);
    background-color: var(--light-accent-color);
    border: 1px solid var(--dark-accent-color);
    border-radius: 4px;
    font-size: 1.618rem;
    visibility: hidden;

    margin:
      var(--gap-minimal)
      var(--gap-S)
      0
      var(--gap-S);
    min-height: calc(1em - 1ex + var(--inputHeight));

    span {
      box-sizing: border-box;
      width: 100%;
      color: var(--dark-accent-color);
      position: absolute;
      top: -1.5em;
      font-size: 1rem;
      text-align: center;
      margin-bottom: 0.3em;
    }

    ul {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: center;
      padding: 0;
      list-style-type: none;
      text-align: center;
      @include heading-style;

      margin: 0;
      padding: calc(1em - 1ex) 0;
    }

    li {
      position: relative;
      line-height: 1.2em;
      margin: 0 calc(var(--inputHeight) / 1.618);
      overflow: hidden;
    }

  }

  .keyword-type-icon {
    width: 1em;
    height: 1em;
    margin-right: 0.309ex;
    transform: translate(0%, 0.309ex);
    cursor: help;
  }

  #keyWordContainer.visible {
    visibility: visible;
    animation: unfold var(--ani-speed) linear;
  }

}

:root[data-theme='dark'] .boxProperty.destinationBox #keyWordContainer {
  span {
    color: var(--this-white)
  }
}

.boxProperty.comment, .boxProperty.category {
  display: none;
  flex-basis: 64ch;
}

.boxProperty.comment.visible, .boxProperty.category.visible {
  display: flex;
}

#duplicate-possible {

  .boxProperty {
    justify-content: left;
    align-items: center;
    position: relative;

    .classyCheckbox {
      margin-left: calc(var(--inputHeight) * 0.618);
      margin-left: 0;
    }

  }

  #entriesNotForMerging {
    position: absolute;
    left: 50%;
    bottom: 2rem;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
    padding: 0 var(--cb-padding);
    color: var(--light-accent-color);
  }

  .boxProperty:first-of-type {
    margin-top: 0;
  }

  h2 {
    margin-top: -0.5ex;
    margin-bottom: var(--gap-M);
  }

  p {
    margin-top: -0.7ex;
    margin-bottom: -0.5ex;
    // text-align: center;
    text-align: left;
    height: min-content;
  }
}



/*
  2.1. Control buttons
*/


.buttons-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  --buttons-vertical-margin: calc(3.3em / 2);
  --buttons-vertical-margin: 0.3em;
  --buttons-top-margin: calc(var(--gap-minimal) + 1.2rem);
  --buttons-bottom-margin: var(--gap-M);


  .button {
    margin: var(--buttons-top-margin) var(--buttons-vertical-margin) var(--buttons-bottom-margin) var(--buttons-vertical-margin);
    width: 3.3em;
    padding: 1em;

    p {
      display: none;
    }
  }

  .icon-square-plus, .icon-trash, .icom-undo, .icon-copy, .icon-comment, .icon-category, .icon-more, .icon-blacklist-off {
    @include in-button-icon;
  }

  .button.trash {
    i:last-of-type {
      display: none;
    }
  }

  .button.trash.fallback {
    background-color: transparent;

    i:first-of-type {
      display: none;
    }

    i:last-of-type {
      display: block;

      svg {
        animation: reverse-rotate linear calc(var(--ani-speed) * 9) infinite,
        fadingOut linear 5s;
        stroke: var(--accent-color) !important;
      }

    }
  }

}

/*
  2.2. Form's state containers
*/

#submit-form-container {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  margin: calc(var(--gap-L) - var(--gap-S)) auto 0 auto;

  .button.submit {
    width: 18ch;
    margin: var(--gap-M) auto 0 auto;
  }

  .button.refresh {
    position: absolute;
    top:0;
    left:0;
    height: 3.5rem;
    width: 3.5rem;

    .icon-refresh {
      width: auto;
      height: 1.618em;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

  }

}

#submit-form-container.invisible {
  display: none;
}

#success-on-submit-container {
  --notification-height: calc(var(--inputHeight) / 1.618);
  display: flex;
  justify-content: center;
  position: absolute;
  width: 24ch;
  height: var(--notification-height);
  top: calc(var(--gap-S) + var(--inputHeight) * 1.618);
  right: 50%;
  transform: translateX(50%);
  background-color: var(--light-accent-color);
  border: 1px solid var(--dark-accent-color);
  border-radius: 4px;
  opacity: 0;
  animation: fadingOut linear 5s;

  p {
    color: var(--dark-shades);
    margin: auto 0.5ex;
    font-size: 0.8rem;
    text-align: center;
  }
}

:root[data-theme='light'] #success-on-submit-container p {
  color: var(--light-shades);
}

#error-on-submit-container {
  display: none;
  width: 100%;
  padding: var(--gap-M) 0;
  margin-top: var(--gap-L);
  @include select-none;

  p {
    color: var(--dark-shades);
    text-align: center;
    max-width: 44ch;
    // margin-left: auto;
    margin-left: 0;

    margin-right: auto;
    // margin-right: 0;

    margin-top: 0;
    margin-bottom: var(--gap-minimal);
  }
  h1 {
    margin-bottom: var(--gap-minimal);
    font-size: 1.618em;
    line-height: 1.2em;
  }

  .appeal{
    margin: 0 auto;
  }


  // p:nth-of-type(1) {
  p:first-of-type {

    @include heading-style;
    font-size: 1.13em;
    margin-bottom: var(--gap-minimal);
    margin-top: var(--gap-S);
  }

  p:nth-last-of-type(2), p:nth-last-of-type(1)  {
    text-align: left;

    @include select-any;

    code {
      margin-left: 0.618em;
    }
  }

  .button.retry {
    margin: var(--gap-M) auto 0 auto;
  }

  #failed-to-send-data {
    padding: 0 var(--gap-minimal) var(--gap-minimal) 0;
    width: 100%;
    overflow: scroll;

    span {
      display: block;
      width: 100%;
      // padding: 0 1.618ch;
      text-align: center;
      height: 2.618ex;
      color: var(--dark-accent-color);
    }

  }

}

#error-on-submit-container.visible {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.loading-icon {
  --loading-icon-size: calc(3rem * 1.618 * 1.618);

  position: relative;
  display: block;
  top: 50%;
  left: 50%;
  transform: translateX(calc(var(--loading-icon-size) / -2.25)) translateY(var(--loading-icon-size));
  opacity: .3;
  stroke: var(--accent-color);
  padding-bottom: calc(var(--loading-icon-size) * 2.618);

  width: var(--loading-icon-size);
  height: auto;
  @include select-none;
  transition: all ease-in-out var(--ani-speed);
}

.loading-icon.invisible {
  display: none;
  opacity: 0;
}


/*
  3. Settings page
*/

#settings-page {
  margin: 0 auto 0 auto;
  max-width: 35ch;
  position: relative;
  @include select-none;


  h1 {
    margin: -0.5ex 0 0 0;
    text-align: center;
    // @include heading-style;
  }

  ul {
    position: relative;
    left: -1.2ch;
    display: flex;
    flex-flow: column wrap;
    padding: 0;
    list-style-type: none;
    text-align: center;
    font-size: 1.618rem;
    margin: var(--gap-S) 0 0 0 ;
  }

  li {
    display: flex;
    flex-flow: row wrap;
    justify-content: right;

    margin: 0 0 var(--gap-minimal) 0;
    font-size: 1rem;
  }

  li:last-of-type {
    margin: 0;
  }

  a.button.to-history, a.button.to-brands {
    padding-bottom: 1.3em;
    text-decoration: none !important;
    margin-left: auto;
    margin-right: auto;
  }

}

.setting-container {
  justify-content: center;
  gap: calc(var(--gap-minimal) / 1.618) 0;

  p {
    text-align: right;
    max-width: calc(64ch - var(--toggle-width));
    margin: auto 0;
    width: 24ch;

    span {
      font-weight: 900;
      // color: var(--light-accent-color);
      // text-decoration: dotted 1px var(--light-accent-color);
      text-decoration: underline var(--light-accent-color) 2px;
    }

  }

  .button.clear-storage, .button.logout {
    height: calc(var(--toggle-diameter) + var(--toggle-offset) * 2);
    width: var(--toggle-width);
    flex-grow: 0;
    margin-left: var(--gap-minimal);
    margin: auto 0 auto var(--gap-minimal);
    padding: 0;
    line-height: 0;

    svg.icon-refresh {
      height: var(--toggle-diameter);
      width: var(--toggle-diameter);
    }

    &:disabled {
      svg.icon-refresh {
        animation: reverse-rotate linear calc(var(--ani-speed) * 3.6) infinite;
      }
    }
  }
}


@media only screen and (max-width: 600px) and (orientation: portrait) {

  #settings-page {
    ul {
      margin-bottom: var(--gap-S);
    }
  }

}

// dev data and controls shown as noted below

#environment-info{
  margin-top: var(--gap-M);
  margin-bottom: var(--gap-L);
  text-align: right;

  .boxProperty {
    max-width: unset;
    min-height: 1.618em;

    .Toggle {
      margin-bottom: 1.618em;
      cursor: pointer;
    }
  }

  code {
    padding: 0.618ch;
  }

}


/*
  3.1 History
*/

#history-container {
  width: 100%;
  margin-top: 0;
  @include select-none;

  h1 {
    margin: 0;
    text-align: center;

  }

  #history-table {
    width:100%;
    overflow: auto scroll;
    padding: var(--gap-M) 0 var(--gap-S) 0;

    .st {
      max-width: 14ch;
    }

    .dest, .comm, .date {
      min-width: 24ch;
      overflow: auto;
      white-space: normal;
      text-overflow: clip;
      word-break: break-all;
    }

    .date {
      min-width: 15ch;
    }

  }

}

/*
  3.2 All brands
*/

#allBrands-table-container {

  .heading-container {
    display: block;
    max-width: calc(100vw - var(--safe-area-horizontal) * 2 - var(--safe-area-horizontal) / 4);
    width: 100%;
    box-sizing: border-box;
    position: sticky;
    position: -webkit-sticky;
    left: var(--safe-area-horizontal);
  }

  h2 {
    box-sizing: border-box;
    text-align: center;
    @include text-style;
    color: var(--dark-shades);
    font-size: 1.618em;
    margin-top: -0.7ex;

  }

  .header-container-selection {
    width: 20ch;

    &:focus-within {
      border-radius: 0;
      outline: none;
    }

  }

}

.button.merge {
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  padding-left: 1.618ch;
  padding-right: calc(1.618em + 1ch);
  padding-bottom: calc(0.618ex + 0.2ex);
  padding-top: 0.618ex;
  outline: none !important;
  transform: translateX(-50%) translateY(calc((1.618ch - 0.618ex - 0.2ex) * -1));


  .icon-merge {
    position: absolute;
    right: 1ch;
    top: 50%;
    width: auto;
    height: 1.618em;
    cursor: pointer;
    transform: scaleX(-100%) translateY(-50%);
  }

}

#merging-brands.loading-icon {
  position: absolute;
  top:50%;
  transform: translate(-50%, -50%);
}

#brands-merge-confirmation {
  max-width: 64ch;

  h2 {
    margin-top: -0.6ex;
    margin-bottom: var(--gap-M);
  }

  p {
    line-height: 1.618em;
    margin-bottom: var(--gap-S);
    margin-top: var(--gap-S);
    text-align: center;
  }
  .button {
    margin-bottom: calc(var(--gap-L) - var(--gap-M));
  }


}

.error-response-container {
  @include select-any;

  h2 {
    margin-top: -0.5ex;
    margin-bottom: var(--gap-M);
  }

  .boxProperty.errCode {
    justify-content: start;
    gap: var(--gap-minimal);
    margin-top: var(--gap-S);
    margin-bottom: var(--gap-S);

    p {
      text-align: start;
      margin: 0;
      code {
        color: var(--tr-accent-color);
      }
    }

  }

}


/*
  4. Navigation
*/

#main-navigation-container {
  position: relative;
  top:0;
  left: 0;
  width: 100%;
  height: var(--gap-L);
  min-width: 24ch;
  overflow: visible;
  z-index: 11;
}

#main-navigation {
  position: sticky;
  position: -webkit-sticky;
  display: inline-flex;
  flex-flow: row;
  left: var(--safe-area-horizontal);
  transform: translateX(calc(var(--safe-area-horizontal) * -1));
}

[id*="nav-"], .nav-dummy-div, .spoiler-header-button.hamburger { // all anchor elements with svg children
  display: inline-block;
  position: relative;
  width: var(--links-size);
  height: var(--links-size);
  text-align: center;
  left: calc(var(--links-size) /2.618 * -1);
  top: calc(var(--links-size) /2.618 * -1);
  color: var(--accent-color);

  svg {
    height: 100%;
    width: auto;
    opacity: .412;
    transition: all linear var(--ani-speed);
  }

  &:hover,
  &:focus {
    outline: none !important;

    svg {
      // filter: drop-shadow(
      //    0 0.05em 0.16em var(--tr-accent-color)
      // );

      opacity: 1;

    }
  }

}

.nav-dummy-div {
  width: calc(var(--links-size) / 2.618);
}

.icon-settings {
  animation: rotate linear calc(var(--ani-speed) * 36) infinite;
}

[id*="nav-"]:not(:first-child) {
  top:0;
}

#nav-Vehicles {
  box-sizing: border-box;
  padding-top: .382rem;
}

#nav-Pedestrians {
  box-sizing: border-box;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

#nav-Add-To-Journal, #nav-MainSearch, #nav-Contacts {
  box-sizing: border-box;
  padding-bottom: .382rem;
  padding-top: .382rem;
}

#nav-MainSearch {
  position: absolute;
  left: calc(100vw - var(--safe-area-horizontal) * 2 - 0.618rem);
}

#nav-Journal, #nav-Accounting {
  box-sizing: border-box;
  padding-bottom: .382rem;
  padding-top: calc(.382rem / 2);
}

#nav-Blacklist {
  padding-top: .382rem;
}

.spoiler.menu-short {
  width: 61.8vw;
  position: absolute;
  top:0;
  left: 50vw;
  transform-origin: center;
  transform: translateX(-50%);
  background-color: var(--light-shades);
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;

  .spoiler-header-button {
    display: block;
    position: static;
    transform: scale(0.618);
  }


  svg.hamburger {

  }

  .spoiler-content-container {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    gap: calc(var(--gap-minimal) / 1.618);
  }

  [id*="nav-"] {
    top: 0;
    left: 0;
  }

}

#background-fade {
  display: none;
  position: absolute;
  top: 0;
  left: calc(var(--safe-area-horizontal) * -1);
  right: 100%;
  bottom: 100%;
  background-color: var(--tr-light-shades);
  width: calc(100% + var(--safe-area-horizontal) * 2);
  height: max(100%, 100vh);
  z-index: 10;
  visibility: hidden;
  opacity: 0;
}

#background-fade.active {
  display: block;

  animation: unfold calc(var(--ani-speed) * 1.618) forwards var(--ani-speed);
}

:root[data-theme='dark'] #background-fade {
  background-color: rgba(0,0,0,0.7);
}


/*
  4.1. Navigation media query
*/

@media only screen and (max-width: 600px) and (orientation: portrait) {

}

/*
  5. Journal table
*/

.blacklist-in-journal-icon {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(21%, -50%);
  cursor: help;
}
// moved to 1.2.1.3. Table hat (spoilered content)

/*
    5.1 File uplading input styling
*/

.file-upload {
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
  position: absolute;
}

.file-upload-label {
  width: max-content !important;
  cursor: pointer;
  color: var(--this-white) !important;
  margin: 0 !important;

}

.file-upload-label.disabled {
  color: var(--light-accent-color) !important;
  background-color: transparent;
  border: 1px solid var(--light-accent-color);
  cursor: not-allowed;
}

.uploaded-files-names {
  position: absolute;
  transform-origin: top left;
  left: 50%;
  top: 100%;
  transform: translateX(-50%);
  color: var(--dark-shades);
  font-size: 0.8em;
  margin: 0;
  padding: 0;
  padding-top: 1.618ex;
  list-style: none;

  li {
    padding: 0;
  }

}

// 5.1 Blacklist

#blacklist-container {
  .spoiler-content-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    gap: var(--gap-S);
  }
}


/*
  5.2 Active deposits in journal
*/

#deposits-container {

  .spoiler-content-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    gap: calc((var(--inputHeight) / 2 - 1em) * 1.618 * 1.618 * 1.618);
    padding-bottom: var(--gap-S);

  }
}


.deposit {
  position: relative;

  .deposit-destination {
    position: relative;
    color: var(--dark-shades) !important;
    margin: 0;
    padding: calc(var(--inputHeight) / 2 - 0.8em) 0;
    overflow: hidden;
    @include select-value;
    background-color: transparent;
    border-color: var(--light-accent-color);
    cursor: help;
  }

  .deposit-category {
    margin: 0;
    margin-top: calc((var(--inputHeight) / 2 - 1em) * 1.618)
  }

  .deposit-available {
    text-decoration: underline 1px var(--tr-accent-color);
    text-underline-offset: 4px;
    margin-left: 1.618ch;
  }

}

.deposit-life-span-container {
  display: block;
  position: absolute;
  bottom: 0;
  left:0;
  width: 100%;
  height: 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: var(--light-accent-color);
  overflow: hidden;

  .deposit-life-span {
    display: block;
    height: 100%;
    width: 61%;
    background-color: var(--tr-dark-shades);
  }

}


/*
  6. Local pedestrians table
*/


#pedestrians-table-container {

  #pedestrians-table-entity {

    .cell-in-name {
      @include select-any;
    }

  }


}

/*
  7. Accounting page
*/

#accounting-page {
  min-width: 100%;
  @include select-none;

  .spoiler {
    max-width: calc(100vw - var(--safe-area-horizontal) * 2 - 1rem);
    position: sticky;
    position: -webkit-sticky;
    left: var(--safe-area-horizontal);
  }

  .spoiler {
    width: max-content;
    max-width: unset;
  }

  .spoiler-toggle {
    position: relative;

    .spoiler-header-button-container {
      width: calc(100vw - var(--safe-area-horizontal) * 2 - 0.5rem);
      position: sticky;
      position: -webkit-sticky;
      left: var(--safe-area-horizontal);
    }
  }

  .Toggle__input:not(:checked) + .Toggle__display {

    &::before {
      border-color: var(--accent-color);
      background-color: var(--tr-accent-color);
    }
  }

  .Toggle__input:focus:focus-visible + .Toggle__display,
  .Toggle__input:hover:not(:disabled) + .Toggle__display {
    box-shadow: none;
    outline-offset: -1px;
    outline: 2px solid var(--accent-color);

    &::before {
      box-shadow: none;
      // outline-offset: -2px;
      // outline: 2px solid var(--this-white);
    }
  }

}

#accounting-debt-container,
#accounting-reports-container {

  .spoiler-content-container {
    padding-left: 2px;
    padding-right: 2px;
  }

  #debt-toggle-container,
  #reports-toggle-container,
  #get-report-container {
    display: block;
    width: calc(100vw - var(--safe-area-horizontal) * 2 - 1rem);
    position: sticky;
    position: -webkit-sticky;
    left: calc(var(--safe-area-horizontal) + 0.2rem);
    // Sticky position stops working when parent's overflow is set to hidden,
    // so the folowing is used to avoid element shifting
    // while overflow of the '.spoiler-veil' is being changed with delay.
    // This element if being invisible while spoiler is closing or opening,
    // so with sticky position on this element
    opacity: 0;
    animation: unfold calc(var(--ani-speed) * 1.618) forwards var(--ani-speed);
  }

  [id$="-table-fake-footer-container"] {
    opacity: 0;
    animation: unfold calc(var(--ani-speed) * 1.618) forwards var(--ani-speed);
    z-index: 1;
  }


}

#accounting-debt-container.collapsed,
#accounting-reports-container.collapsed {

  #debt-toggle-container,
  #reports-toggle-container,
  #get-report-container {
    animation: none;
    opacity: 0;
  }

  [id$="-table-fake-footer-container"] {
    animation: none;
    opacity: 0;
  }

}


/*
  7.1. Debts
*/

#accounting-debt-container {

  .Toggle.debt-toggle {
    position: relative;
    display: flex;
    width: 35ch;
    left: calc(50vw - var(--safe-area-horizontal));
    transform: translateX(-50%);
    flex-flow: column-reverse;
    margin-bottom: var(--gap-minimal);
    gap: 0;
    text-align: center;
    font-size: 1.13em;
    cursor: pointer;

    .Toggle__display {
      margin-left: 0;
      margin-bottom: calc(var(--gap-minimal) / 1.618);
    }

    .Toggle__input:not(:checked) + .Toggle__display {

      &::before {
        border-color: var(--accent-color);
        background-color: var(--tr-accent-color);
      }
    }
  }

  #debt-table-container {

    td.cell-in-parking {
      width: 33ch;
      overflow: visible;
    }

  }

}


/*
  7.2. Short on deposits
*/

#accounting-short-deposits-container {
  width: calc(100vw - 8.5rem);
  // padding-bottom: var(--gap-L);
  .spoiler-content-container {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
  }
}

#deposits-list-container {
  max-width: 61.8%;
  margin-right: var(--gap-S);
  margin-left: auto;
  text-align: center;

  ul {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    gap: var(--gap-minimal);
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .link-to-deposit {
    text-decoration: none;
    color: var(--dark-shades);

    &:hover, &:focus-visible {
      outline: 2px solid var(--tr-accent-color);
      outline-offset: -1px;
      border-radius: 4px;

      li {
        border-color: var(--accent-color);
      }

    }
  }

  li {
    position: relative;
    display: flex;
    text-align: center;
    align-items: center;
    padding: 1.618ex;
    border: 1px solid var(--light-accent-color);
    border-radius: 4px;

    p {
      padding: 0;
      margin: 0;
    }

    span {
      color: var(--light-accent-color);

      strong {
        color: var(--dark-accent-color);
        margin-left: 1.618ch;
        font-size: 1.618em;
        font-weight: 400;
      }

    }

  }

}

.add-deposit-container {
  margin-right: auto;
}

.button.add-deposit {
  position: relative;
  padding-left: 2.7em;
  padding-top: 1.618ex;
  padding-bottom: 1.618ex;

  p {
    line-height: 2.4rem;
    margin:0;
    transform: translateY(-0.25ex);
  }

  i {
    position: absolute;
    left : 1.2rem;
    bottom: 50%;
    transform: translate(25%, 1rem);
    font-style: normal !important;
    font-size: 1.618em;
    font-weight: 900;
  }
}

#link-to-deposits-container{
  width: 100%;
  text-align: center;

  #link-to-deposits {
    display: block;
    height: min-content;
    margin-top: var(--gap-S);
  }

}

/*
  7.2.1. Add deposit modal dialog
*/

#add-deposit-wrapper {
  min-width: 44ch;
  min-height: 44ch;

  #deposit-success-container {

    p {
      margin:0;
      font-size: 1.618em;
      margin-top: var(--gap-L);

    }

    .button.repeat {
      margin-top: var(--gap-M);
      margin-bottom: var(--gap-L);
    }
  }

  #error-on-submit-container {
    margin-top: 0;
    padding-top: 0;
    margin-bottom: calc(var(--cb-safe-area) - var(--cb-padding));

    .appeal {
      margin: 0;
    }

  }

}

#add-deposit-wrapper.loading {
  overflow: hidden;
}

#add-deposit-form {
  display: none;

  .boxProperty:first-child {
    margin-top: 0;
  }
  .boxProperty .propertyContainer label {
    transform: translateX(-50%);
  }

  #submit-form-container {
    margin-bottom: calc(var(--cb-safe-area) - var(--cb-padding));
    margin-top: 0;

    .button {
      margin: 0;
    }

  }
}

#add-deposit-form.visible {
  display: block;
}


/*
  7.3. Construction areas reports
*/

#reports-toggle-container {
  --label-font-size: 1.13rem;
  width: calc(100vw - 9rem);
  max-width: unset;
  margin-left: 0;
  margin-right: 0;
  margin-top: calc(var(--gap-L) - var(--gap-S) - var(--label-font-size) * 1.3);
  margin-bottom: var(--gap-S);
  z-index: 11;

  & > [class*="-container"] {
    width: 24ch;
    margin-left: auto;
    margin-right: auto;
  }


  #label-for-SelectAreaForReport {
    color: var(--light-accent-color);
    position: absolute;
    font-size: var(--label-font-size);
    top: calc(var(--label-font-size) * -1 - 0.618ex);
    left: 50%;
    transform: translateX(-50%);
    width: max-content;
  }

  .icon-receipt {
    position: absolute;
    left: 0.618em;
    top: 0;
    height: 1.618em;
    transform: translateY(20%);
    width: auto;
    color: var(--dark-shades);
  }

  .area-option {
    position: relative;
    border: none;
    padding: 1ex 1.618em 1ex 1.618em;
    cursor: pointer;
  }

  .area-option:hover {
    background-color: var(--tr-accent-color);
    color: var(--light-shades);

    .icon-receipt {
      color: var(--light-shades);
    }
  }

  .Toggle.reports-toggle {
    position: absolute;
    width: 12ch;
    left: 50%;
    top: calc((var(--label-font-size) + 0.3em + var(--inputPadding)) * -1);
    transform: translateX(calc(20ch - 50%)) translateY(-0%);
    display: flex;
    flex-flow: column;
    gap: 0;
    margin: 0;
    font-size: var(--label-font-size);
    text-align: center;
    color: var(--light-accent-color);
    cursor: pointer;

    .Toggle__display {
      margin-top: calc(var(--inputPadding) + 0.3em);
      margin-left: 0;
    }

  }

}

#reports-table-container {
  min-height: 44ch;
}

#reports-table-entity {

  th {

    .header-container-selection {
      width: calc( var(--inputHeight) * 2.618);
      height: 100%;
      position: relative;
      padding: 0;

      input {
        position: absolute;
        bottom: 50%;
        left: 50%;
        transform-origin: center bottom;
        transform: translate(-50%, -50%);
      }

    }

    &:focus-within {
      border-radius: 0;
      outline: none;
    }

  }

  td.cell-in-parking {
    width: 33ch;
    overflow: visible;
  }

}

.classyCheckbox.selectRow {
  width: calc(var(--inputHeight) / 1.618);
  height: calc(var(--inputHeight) / 1.618);
}

.classyCheckbox.selectRow:checked::before {
  transform: scale(0.382);
}

#get-report-container {
  display: flex !important;
  margin-top: var(--gap-M);
  margin-bottom: var(--gap-L);
  justify-content: center;
  align-items: center;

  .button.makeReport {
    width: min-content;
    height: calc(var(--inputHeight) * 1.618);
  }

  #report-info-block {
    width: 50ch;
    padding-right: var(--gap-minimal);
    @include select-any;

    p {
      margin: 0;
    }
  }
}


/*
  8. All deposits page
*/

#deposits-table-container {
  #toggle-container {
    .Toggle {
      width: 25ch;
    }
  }
}

#deposits-table-entity {

  .cell-in-limit {

    .classyInput {
      width: 6ch;
      text-align: center;
      margin: 0 auto;
    }

  }

  .cell-in-expiration_date {

    p {
      margin: 0 auto;
      white-space: normal !important;
      width: 13ch;
    }

  }

  .cell-in-status {

    & > span {
      margin-left: auto;
    }

    p {
      display: inline-flex;

      span {
        border-radius: 0;
        padding-left: 1.618ch;
        margin-left: 1.618ch;
        border-top: none;
        border-bottom: none;
        border-right: none;
      }

    }

  }


  .entered_vehicles-list {
    padding: 0;
    margin:0;
    list-style: none;
    margin-top: -0.618em;

    li {
      position: relative;
      line-height: 1.618em;

      i {
        position: relative;
        right: -0.618ch;
        top: 0;
        cursor: help;

        svg {
          transform: translateY(25%);
          color: inherit;
          height: 1.618em;
          width: auto;
        }
      }
    }

  }

  .prolong-deposit {
    color: var(--tr-accent-color);
    transition: all linear var(--ani-speed);
    line-height: 0.1em;

    svg {
      height: 1.618em;
      width: auto;
    }

    &:hover:not(:disabled) {

      color: var(--accent-color);
      filter: drop-shadow(
        0 0em 0.06em var(--tr-accent-color)
      );

    }

  }
  .prolong-deposit:disabled {
    color: var(--light-accent-color);
    cursor:auto;
  }
}



/*
 9. NotWelcomePage
*/

#welcome-container {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: var(--gap-L) auto 0 auto;
  position: relative;
  text-align: center;
  @include select-none;

  h1 {
    margin: 0;
  }

  p.username {
    font-size: calc(2rem / 1.618);
    margin: 1em 0 1.618em 0;

    span {
      color: var(--accent-color);
    }
  }

  .button.logout {
    width: max-content;
    margin: 0 auto;
  }


}

/*
 10. Blacklist page
*/

#blacklist-page {
  width: calc(100vw - var(--safe-area-horizontal) * 2 - var(--safe-area-horizontal) / 4);

  @include select-none;

  h2 {
    box-sizing: border-box;
    text-align: center;
    @include text-style;
    color: var(--dark-shades);
    font-size: 1.618em;
    margin-top: -1ex;
    margin-bottom: var(--gap-S);
  }

  #blacklist-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    gap: var(--gap-S);

  }

  #add-to-blacklist-container {
    width: 100%;

    .button.add-to-blacklist {
      margin-left: auto;
      margin-right: auto;
    }

  }

}


/*
  11. Main (~global) search
*/

#main-search-container {
  position: relative;
  display: flex;
  flex-flow: column;
  max-width: calc(100vw - var(--safe-area-horizontal) * 2 - var(--safe-area-horizontal) / 4);
  align-items: center;
  justify-content: center;
  gap: var(--gap-M);

  #search-label {
    position: relative;
    width: max-content;
    margin-bottom: 0.5ex;
    top:0;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  #main-search-field {
    width: 100%;
  }

}

.search-field-container {
  box-sizing: border-box;
  flex-direction: column !important;

  #search-time {
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    font-size: 0.8em;
    color: var(--light-accent-color);
    margin: 0;
    transform: translate(-1.618ch, 100%);
  }

}

.progress-container {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 4px;
  width: 100%;
  height: 10px;
  overflow: hidden;

  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: -200%;
    width: 300%;
    height: 6px;
    background: repeating-linear-gradient(to left, transparent, var(--tr-accent-color) 25%, transparent 50%, var(--tr-accent-color) 75%, transparent );
    z-index: -1;
    opacity: 0;
    transition: opacity var(--ani-speed) ease-in-out;
    animation: loading-line calc(var(--ani-speed) * 4.618) cubic-bezier(.39,0,.61,.61) infinite;
  }

}

.search-field-container.searching {

  .progress-container::after {
    opacity: 1;
  }

}




.search-results-group {
  --min-gap: calc(var(--gap-minimal) / 1.618);
  position: relative;
  padding: var(--gap-S);
  border-radius: 4px;
  border: 1px solid var(--light-accent-color);


  ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    gap: var(--gap-minimal);

    li {
      position: relative;
      display: flex;
      flex-flow: row wrap;
      text-align: center;
      border-radius: 4px;
      border: 1px solid var(--light-accent-color);
      padding: var(--min-gap);
      padding-right: calc(var(--gap-S) + var(--min-gap));
      gap: var(--min-gap);
      overflow: hidden;
    }

  }

  .more-entries {
    position: absolute;
    bottom: 0;
    left: 50%;
    line-height: 1em;
    transform: translateX(-50%) translateY(calc(50% - 0.25ex));
    background-color: var(--light-shades);
    border-radius: 50%;
    padding-left: 1.618ch;
    padding-right: 1.618ch;
    @include select-none;
  }

}

.s-data-block {
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.s-number, .s-name, .s-position {
  position: relative;
  font-size: 1.13em;
  letter-spacing: 1px;
  margin-top: -0.5ex;
  margin-bottom: 0;

  .vehicle-mark {
    margin-right: calc(0.618ch / 1.2);
    font-size: 1em;
    bottom: 10%;
    position: relative;
  }

}

.s-brand {
  padding: 0;
  margin-top: -0.25ex;
  margin-bottom: 0;

}

.s-dest, .s-powersStatus  {
  margin-top: -0.5ex;
  padding-top: calc(var(--min-gap) / 1.618);
  margin-bottom: 0;
}

.s-sanction {
  color: var(--accent-color);
}

.s-time-label {
  font-size: 0.618em;
  width: max-content;
  padding-top: calc(var(--min-gap) / 1.618);
  margin-top: auto;
  margin-bottom: 0;
}

.s-time {
  width: 11ch;
  margin-top: 0;
  margin-bottom: 0;
}

.s-link-block {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  width: var(--gap-S);
  height: 100%;
  background-color: var(--light-accent-color);
  transition: all ease-out var(--ani-speed);

  &:focus-within, &:hover {
    background-color: var(--dark-shades);
  }


  .s-link {
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--gap-S);
    width: var(--gap-S);
    margin-top: auto;
    margin-bottom: auto;

    svg {
      width: 61.8%;
    }

    &:hover,
    &:focus {
      outline: none !important;
    }

  }
}

.s-link-block[s-disabled='true'] {
  pointer-events: none;

  .s-link {
    svg {
      color: var(--dark-accent-color);
    }
  }

}


/*
  12. Contacts page
*/

#suspects-list {
  list-style: none;
  max-width: 64ch;
  margin-left: auto;
  margin-right: auto;
  padding: 0;

  li {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    gap: 1.618ex;
    align-items: center;
    justify-content: center;
    padding: var(--gap-minimal) var(--gap-S);
    border-bottom: var(--toggle-border-style) ;

    & > p {
      margin: 0;
      @include select-any;
    }

    .powers-status {
      width: 100%;
    }

    &:last-of-type {
      border-bottom: none;
    }

    &:first-of-type {
      margin-top: calc(var(--gap-minimal) * -1);
    }

  }

  .land-object {
    padding: calc(var(--inputHeight) / 2 - 0.8em) 0;
    @include land-object;
  }

  .button.filter-table-below {
    position: absolute;
    right: 0;
    top: var(--gap-minimal);
    height: calc(100% - var(--gap-minimal) - var(--gap-minimal));
    background-color: #FE016C;
    padding: 0 0.618ch;
    line-height: 0;
    width: max-content;
    height: max-content;
    transform: translateY(25%);

    svg {
      width: calc(var(--gap-S) - 0.618ch - 0.618ch);
    }

    .icon-filter-off {
      display: none;
    }

    .icon-filter {
      display: block;
    }

  }

  // .button.filter-table-below[data-state="filter-not-set"] {

  // }

  .button.filter-table-below[data-state="filter-set"] {

    .icon-filter {
      display: none;
    }

    .icon-filter-off {
      display: block;
    }

  }

}


#column-filter-id-power_of_attorney_expiration {
  width: 34ch;
}

td.boxProperty.cell-in-phone,
td.boxProperty.cell-in-email,
td.boxProperty.cell-in-email,
td.boxProperty.cell-in-name {
  @include select-any;
}

[id$="-table-entity"] td.cell-in-phone.boxProperty {
  max-width: 48ch;
  --phohe-cell-safe-area: calc(var(--gap-minimal) + 3.236ch);
  padding-right: var(--phohe-cell-safe-area);
  padding-left: var(--phohe-cell-safe-area);

  p {
    white-space: normal;
    margin: 0;
    line-height: var(--inputHeight);
  }

  .classyInput {
    width: 100%;
  }

}

.phone-number {
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 1.618ch;
  padding: 1.618ex 1.618ch;
  padding: 0 1.618ch;
  padding-left: 1.618ch;
  padding-right: 0.618ch;

  a {
    display: inline-flex;
    width: 0;
    height: 1em;
    cursor: pointer;

    svg {
      transition: all linear var(--ani-speed);
      color: var(--tr-accent-color);
    }

    &:hover, :focus {

      svg {
        color: var(--accent-color);

        filter: drop-shadow(
          0 0.05em 0.16em var(--tr-accent-color)
        );

      }

    }

    &:focus {
      outline: none;
    }

  }

  .icon-phone-call {
    position: absolute;
    height: 1.618em;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: auto;
  }

  &:focus-within {
    text-decoration: underline 1.5px solid var(--accent-color);
    text-underline-offset: 3px;
  }

}



.enterEditModeButton {
  position: absolute;
  right: calc(var(--phohe-cell-safe-area) * -1);
  bottom: calc(1.618ch * -1);
  padding: 1.618ch;

  @include inner-active-svg;

  svg {
    color: var(--tr-accent-color);
    height: 1.618em;
    width: auto;
  }

}


/*
    13. Local vehicles table
*/

#localVehicles-table-container {

  #localVehicles-table-entity {
    // happy being non-unique

    .cell-in-id_issue {
      overflow: visible;
      width: 32ch;
    }

  }

}

.vehicle-number {
  padding: 0;
  margin: 0;
}

.vehicle-mark {
  margin-right: 0.618ch;
  font-size: 1.4em;
  font-style: normal;
  display: inline-block;
  transform: translateY(0.1rem);
}
